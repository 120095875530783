import { useTheme } from '@mui/material';
import { MdNoteAdd } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { PageContent } from 'components/Page';
import { PageContentAction } from 'components/Page/PageContent';

export function RelationshipNotesEmpty() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { relationshipId } = useParams();
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const notes_modal_empty_title =
    baseLanguage.relationships?.notes?.notes_modal?.notes_modal_empty_state
      ?.notes_modal_empty_title;
  const notes_modal_empty_description =
    baseLanguage.relationships?.notes?.notes_modal?.notes_modal_empty_state
      ?.notes_modal_empty_description;
  const notes_modal_empty_create_button_label =
    baseLanguage.relationships?.notes?.notes_modal?.notes_modal_empty_state
      ?.notes_modal_empty_create_button_label;

  const actions: PageContentAction[] = [
    {
      label: notes_modal_empty_create_button_label,
      action: () => navigate(`/relationships/${relationshipId}/notes/create`),
      color: 'info',
      'data-cy': 'relationship-notes-empty-create-note-button',
    },
  ];

  return (
    <PageContent
      title={notes_modal_empty_title}
      subtitle={notes_modal_empty_description}
      icon={MdNoteAdd}
      iconColor={theme.palette.warning.main}
      actions={actions}
      containerStylings={{
        minHeight: '100%',
        border: 'none',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          margin: 0,
        },
      }}
    />
  );
}
