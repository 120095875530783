import Person from '@mui/icons-material/Person';
import { Avatar, Typography, Box, useTheme } from '@mui/material';

// Hooks
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

export const RelationshipsEmpty = () => {
  const theme = useTheme();
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        py: 1,
        px: 1.5,
        mt: 1,
      }}
      data-cy="no-relationships-list"
    >
      <Avatar
        variant="rounded"
        sx={{ width: 29, height: 29, backgroundColor: '#EEEEEE', mr: 1 }}
        data-cy="no-relationships-list-avatar"
      >
        <Person sx={{ color: theme.palette.action.active }} />
      </Avatar>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ letterSpacing: '0.4px' }}
        data-cy="no-relationships-list-title"
      >
        {baseLanguage.relationships?.relationships_list?.no_relationships}
      </Typography>
    </Box>
  );
};
