// external
import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import React, { useEffect } from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// internal
import {
  getISOStringWithoutMilliseconds,
  getSubDomain,
} from '@guider-global/front-end-utils';

// components
import { SessionsCreateForm } from 'forms';

// store
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';

// hooks
import {
  useLocalization,
  useMixpanelEvents,
  useProfiles,
  useRelationships,
  useSessions,
} from 'hooks';

// types
import { getSessionTitle } from '@guider-global/isomorphic-utils';
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  IRelationship,
  ISession,
  ISessionVideoConferencing,
} from '@guider-global/shared-types';
import { ModalCard } from 'components';
import { IButtonAction } from 'components/ActionButton';
import { getVideoConferencingProvider } from 'utils/getVideoConferencingProvider';

export interface ISessionInputs {
  title: string;
  date: Date;
  startTime: string;
  endTime: string;
  location: string;
  videoConferencing: ISessionVideoConferencing;
}

interface IRelationshipSessionsCreateProps {
  relationship: IRelationship;
  handleClose: () => void;
  timezone: string;
}

export const RelationshipSessionsCreate: React.FC<
  IRelationshipSessionsCreateProps
> = ({ relationship, handleClose, timezone }) => {
  //base language
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const sessionsBaseLanguage = baseLanguage?.relationships?.sessions;
  //hooks
  const { trackScreenTime } = useMixpanelEvents();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reqRelationships, isLoadingRelationships } = useRelationships({
    getSilently: false,
  });

  //form
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm<ISessionInputs>({ mode: 'onChange' });
  const typedControl: Control = control as unknown as Control;

  //profiles
  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();
  const guideProfiles = relationship.guideProfiles ?? [];
  const traineeProfiles = relationship.traineeProfiles ?? [];

  //sessions
  const { reqSessions, isLoadingSessions } = useSessions({});

  const currentSessions = relationship.sessions as Partial<ISession>[];

  const programSlug = relationship.programSlug;
  const { programs } = useOrganizationPrograms({ organizationSlug });
  const program = programs.find(
    (program) => program.metadata.id.current === programSlug,
  );

  useEffect(() => {
    trackScreenTime(
      'Relationship - Session - Schedule new session - Form viewed',
      {
        Started: getISOStringWithoutMilliseconds(),
      },
    );
  }, [trackScreenTime, navigate]);

  useEffect(() => {
    trackScreenTime(
      'Relationship - Session - Schedule new session - Form viewed',
      { Started: getISOStringWithoutMilliseconds() },
    );
  }, [trackScreenTime]);

  //handlers
  const programName = program?.metadata?.program_name;

  const trackSessionCreateFormSubmit = (
    videoConferencing: ISessionVideoConferencing,
    location: any,
  ) => {
    trackScreenTime(
      'Relationship - Session - Schedule new session - Form submitted',
      {
        'Session count': currentSessions.length + 1,
        'Video conferencing': Boolean(videoConferencing),
        'Video conferencing provider':
          getVideoConferencingProvider(videoConferencing),
        Location: Boolean(location),
      },
    );
  };

  const onSubmit: SubmitHandler<ISessionInputs> = async (data) => {
    const { date, startTime, endTime, videoConferencing, location } = data;

    const [startHours, startMinutes] = startTime.split(':');
    const newStart = toDate(date);
    newStart.setHours(parseInt(startHours), parseInt(startMinutes));

    const [endHours, endMinutes] = endTime.split(':');
    const newEnd = toDate(date);
    newEnd.setHours(parseInt(endHours), parseInt(endMinutes));

    const startUTC = timezone ? zonedTimeToUtc(newStart, timezone) : newStart;
    const endUTC = timezone ? zonedTimeToUtc(newEnd, timezone) : newEnd;

    const sessionsResponse = await reqSessions({
      method: 'POST',
      url: `/sessions?organizationSlug=${organizationSlug}`,
      data: {
        name: data.title,
        organizationSlug,
        programSlug,
        relationshipId: relationship.id,
        description: '',
        participantProfiles: [
          ...traineeProfiles.map((trainee) => trainee.id ?? ''),
          ...guideProfiles.map((guide) => guide.id ?? ''),
        ],
        start: startUTC,
        end: endUTC,
        timezone: timezone,
        hasVideoConferencing: Boolean(videoConferencing),
        videoConferencing: videoConferencing,
        ownerProfile: profile.id,
        location,
      },
    });

    if (sessionsResponse.status !== 'success') {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: sessionsBaseLanguage?.new_session_modal?.error_alert_text,
          timeout: 5000,
        }),
      );
    } else {
      trackSessionCreateFormSubmit(videoConferencing, location);

      await Promise.all([
        reqSessions({ url: '/sessions' }),
        reqRelationships({ url: '/relationships' }),
      ]);

      dispatch(
        showAppAlert({
          severity: 'success',
          message: sessionsBaseLanguage?.new_session_modal?.success_alert_text,
          timeout: 5000,
        }),
      );

      handleClose();
    }
  };

  const modalActions: IButtonAction | IButtonAction[] = [
    {
      label: baseLanguage?.globals?.common?.cancel_button_label,
      color: 'info',
      variant: 'outlined',
      action: handleClose,
      dataCyLabel: 'relationship-create-session-modal-cancel-button',
    },
    {
      label:
        sessionsBaseLanguage?.new_session_modal
          ?.send_calendar_invite_button_label,
      variant: 'contained',
      color: 'info',
      action: handleSubmit(onSubmit),
      isLoadingButton: true,
      disabled: !isValid,
      dataCyLabel: 'relationship-create-session-modal-submit-button',
    },
  ];

  return (
    <ModalCard
      title={sessionsBaseLanguage?.new_session_modal?.title}
      description={sessionsBaseLanguage?.new_session_modal?.subtitle}
      handleClose={handleClose}
      actions={modalActions}
      isLoading={isLoadingSessions || isLoadingRelationships()}
      data-cy="relationship-create-session-modal"
      closeIconButtonDataCy="relationship-create-session-modal-close-icon-button"
    >
      <SessionsCreateForm
        handleSubmit={handleSubmit(onSubmit)}
        control={typedControl}
        errors={errors}
        isValid={isValid}
        isLoadingSessions={false}
        discardAction={() => navigate(-1)}
        setValue={setValue}
        defaultTitle={
          programName ? getSessionTitle(relationship, programName) : ''
        }
        hideButtons
        timezone={timezone}
      />
    </ModalCard>
  );
};
