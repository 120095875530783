//external
import { useProfileImage } from '@guider-global/azure-storage-hooks';
import { EProgramVariation, IRelationship } from '@guider-global/shared-types';
import { GroupAvatar } from '@guider-global/ui';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Typography,
  useTheme,
} from '@mui/material';
import { useProfiles } from 'hooks';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectIsUnreadMessages } from 'store/slices/relationshipThreadsSlice';
import { getRecipients } from 'utils/getRelationshipRecipients';

interface IRelationshipItemProps {
  relationship: IRelationship;
}

export const RelationshipItem: React.FC<IRelationshipItemProps> = ({
  relationship,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { relationshipId } = useParams();

  // Redux
  const isUnreadMessages = useAppSelector(
    selectIsUnreadMessages(relationship.id),
  );

  const isGroupProgram =
    relationship?.programVariationTypeSlug === EProgramVariation.Group;

  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();
  const profileId = profile?.id;
  const userId = profile?.userId;

  const isActive = relationshipId === relationship.id;

  const isGuide = Boolean(
    relationship.guideProfiles?.find((guide) => guide.id === profileId),
  );
  const recipients = getRecipients({
    role: isGuide ? 'guide' : 'trainee',
    relationship,
    userId,
  });

  const guidesCount = relationship?.guideProfiles?.length ?? 0;
  const traineesCount = relationship?.traineeProfiles?.length ?? 0;

  const otherPartyProfile = recipients.at(0);
  const { getProfileImage } = useProfileImage();

  return (
    <Button
      sx={{
        color: theme.palette.text.primary,
        textTransform: 'none',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 1,
        px: 1.5,
        my: 1,
        backgroundColor: isActive
          ? theme.palette.secondary.dark
          : 'transparent',
        cursor: 'pointer',
        '&.MuiButton-root:hover': {
          backgroundColor: theme.palette.secondary.dark,
          color: '#FFFFFF',
        },
        ...(isActive && { color: '#FFFFFF' }),
      }}
      onClick={() => navigate(`./${relationship.id}`)}
      variant="text"
      fullWidth
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {isGroupProgram ? (
          <Badge
            sx={{
              mr: 1,
              '& .MuiBadge-badge': {
                color: '#FFFFFF',
                borderRadius: '5px',
                backgroundColor: '#8A8A8A',
              },
            }}
            badgeContent={guidesCount + traineesCount}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <GroupAvatar width={29} height={29} />
          </Badge>
        ) : (
          <Avatar
            src={getProfileImage({
              profilePicture: otherPartyProfile?.picture,
              userPicture: otherPartyProfile?.userPicture,
            })}
            sx={{ height: '29px', width: '29px', mr: 1.5 }}
            variant="rounded"
          />
        )}
        <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {relationship && (
            <Typography variant="subtitle2" noWrap>
              {isGroupProgram
                ? relationship?.title
                : otherPartyProfile?.displayName}
            </Typography>
          )}
        </Box>
      </Box>
      {isUnreadMessages && (
        <Box
          sx={{
            borderRadius: '50%',
            width: 12,
            height: 12,
            backgroundColor: 'info.main',
          }}
        />
      )}
    </Button>
  );
};
