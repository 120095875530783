//external
import {
  Box,
  Button,
  IconButton,
  Typography,
  lighten,
  useTheme,
} from '@mui/material';
import React, { useContext } from 'react';

//types
import { INote } from '@guider-global/shared-types';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import People from '@mui/icons-material/People';

//hooks
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useMobileMediaQuery } from 'hooks';

import { RelationshipNotesModalContext } from 'modals';

interface IRelationshupNotesList {
  notes: Partial<INote>[];
}

export const RelationshipNotesList: React.FC<IRelationshupNotesList> = ({
  notes,
}) => {
  //hooks
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const navigate = useNavigate();
  const { relationshipId, noteId } = useParams();
  const { setOnConfirmDiscardNoteChanges } = useContext(
    RelationshipNotesModalContext,
  );

  //style
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const notesCount = notes ? notes.length : 0;
  const notesCountDescription =
    notesCount === 1
      ? `${notesCount} ${baseLanguage.relationships?.notes?.notes_modal?.notes_sidebar?.notes_sidebar_single_note_label}`
      : `${notesCount} ${baseLanguage.relationships?.notes?.notes_modal?.notes_sidebar?.notes_sidebar_multiple_notes_label}`;

  const navigateToNote = (noteId: string | undefined) => {
    navigate(`/relationships/${relationshipId}/notes/${noteId}`);
  };

  const navigateToNoteCreate = () => {
    navigate(`/relationships/${relationshipId}/notes/create`);
  };

  const handleClick = (callback: () => void) => {
    setOnConfirmDiscardNoteChanges(() => callback);
  };

  //renders
  const renderNotesItems = () =>
    notes.map((note, index) => (
      <Button
        variant="text"
        key={`notes-list-item-${index}`}
        onClick={() => handleClick(() => navigateToNote(note.id))}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: { xs: 2, md: 3 },
          py: 1,
          borderRadius: 0,
          textTransform: 'unset !important',
          ...(note.id === noteId && {
            backgroundColor: `${lighten(theme.palette.info.main, 0.9)}`,
          }),
        }}
        data-cy={`relationship-note-${note.id}`}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            mr: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {note.title}
        </Typography>

        <People color="disabled" sx={{ opacity: note.isPrivate ? 0 : '' }} />
      </Button>
    ));

  //component

  return (
    <Box
      sx={{
        width: isMobile ? '100%' : '30%',
        minWidth: isMobile ? 'unset' : '30%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      }}
      data-cy="relationship-notes-list-container"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 0.25,
          paddingLeft: { xs: 2, md: 3 },
          paddingRight: { xs: 1.25, md: 2.25 },
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Typography
          color="text.secondary"
          variant="body2"
          data-cy="relationship-notes-list-note-count"
        >
          {notesCountDescription}
        </Typography>
        <IconButton
          sx={{ p: 0.75 }}
          onClick={() => handleClick(navigateToNoteCreate)}
          data-cy="relationship-notes-list-add-icon-button"
        >
          <Add color="disabled" fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
        data-cy="relationship-notes-list"
      >
        {renderNotesItems()}
      </Box>
      <Button
        variant="outlined"
        color="info"
        startIcon={<Edit />}
        sx={{
          mx: { xs: 2, md: 3 },
          my: 2,
          fontWeight: 600,
          whiteSpace: 'nowrap',
        }}
        onClick={() => handleClick(navigateToNoteCreate)}
        data-cy="relationship-notes-list-create-note-button"
      >
        {
          baseLanguage.relationships?.notes?.notes_modal?.notes_sidebar
            ?.notes_sidebar_new_note_button_label
        }
      </Button>
    </Box>
  );
};
