//external
import { Box, Divider, ThemeProvider, useTheme } from '@mui/material';
import { useState } from 'react';

//hooks
import {
  useOrganization,
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';

//types
import {
  IMembership,
  IRelationship,
  ISkill,
  Program,
  SanityProgramTypeTextValue,
} from '@guider-global/shared-types';
import {
  MembershipDataProps,
  ProfilePreviewProfileData,
} from 'components/ProfilePreview';
import { useProfiles } from 'hooks';

import { RelationshipViewProfileModal } from '../RelationshipViewProfileModal';
import { Button, theme } from '@guider-global/ui';
import { deepMerge } from 'utils';
import { interpolate } from 'functions';
import { getSubDomain } from '@guider-global/front-end-utils';

interface RelationshipViewProfileProps {
  relationship: IRelationship;
}

export function RelationshipViewProfile({
  relationship,
}: RelationshipViewProfileProps) {
  //state
  const [selectedUserId, setSelectedUserId] = useState<string>();

  // Organization
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({ organizationSlug });

  //style
  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  //base language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const modalTitle = baseLanguage.relationships?.view_profile_modal?.title;
  const modalCloseButtonLabel =
    baseLanguage.globals?.common?.close_button_label ?? 'Close';

  // Profile
  const { getProfiles } = useProfiles({ getSilently: false });
  const [profile] = getProfiles();
  const profileId = profile?.id;

  const guideProfiles = relationship.guideProfiles ?? [];
  const traineeProfiles = relationship.traineeProfiles ?? [];

  //program
  const programSlug = relationship?.programSlug;
  const { getProgram } = useSanityOrganizationPrograms({});

  let program: Program | undefined;
  let programTypeText: SanityProgramTypeTextValue | undefined;
  if (programSlug) {
    program = getProgram(programSlug);
    programTypeText = program?.program_details?.program_type?.program_type_text;
  } else {
    programTypeText =
      organization?.open_matching.program_type?.program_type_text;
  }

  // TODO: reinstate view profile for open matching - currently set up for Memberships only

  if (!programSlug) return;

  const otherUsers = [...guideProfiles, ...traineeProfiles].filter(
    (profile) => profile.id !== profileId,
  );

  //handlers
  const handleModalClose = () => setSelectedUserId(undefined);
  const handleModalOpen = (userId?: string) => setSelectedUserId(userId);

  const getSelectedUsersProfileData = (
    selectedUserId?: string,
  ): ProfilePreviewProfileData => {
    const guideProfiles = relationship.guideProfiles ?? [];
    const traineeProfiles = relationship.traineeProfiles ?? [];
    const profiles = [...guideProfiles, ...traineeProfiles];

    const selectedProfile = profiles.find(
      (profile) => profile.userId === selectedUserId,
    );

    const selectedProfileUserId = selectedProfile?.userId;

    const selectedUser = relationship.users?.find(
      (user) => user.id === selectedProfileUserId,
    );
    return {
      ...selectedProfile,
      pictures: {
        profile: selectedProfile?.picture,
        user: selectedUser?.picture,
      },
    };
  };

  const getSelectedUsersMembershipData = (
    selectedUserId?: string,
  ): MembershipDataProps => {
    const guideMemberships = relationship.guideMemberships ?? [];
    const traineeMemberships = relationship.traineeMemberships ?? [];
    const memberships = [...guideMemberships, ...traineeMemberships];

    const selectedMembership = memberships.find(
      (membership) => membership.userId === selectedUserId,
    );

    const skillFieldSlugs = selectedMembership?.skills?.map((skill) => {
      const skillTyped = skill as ISkill;
      return skillTyped.fieldSlug;
    });
    return {
      skills: skillFieldSlugs ?? [],
      membership: selectedMembership ? [selectedMembership as IMembership] : [],
    };
  };

  const getSelectedUserRole = (selectedUserId?: string) => {
    const guideProfile = relationship.guideProfiles?.find(
      (guideProfile) => guideProfile.userId === selectedUserId,
    );
    if (guideProfile) return 'guide';
    return 'trainee';
  };

  return (
    <>
      <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
        <Box sx={{ md: 2 }}>
          {otherUsers.map((otherUser) => {
            return (
              <Button
                key={`${otherUser.userId}`}
                daty-cy="components_relationship_relationshipViewProfile_button"
                fullWidth
                variant="outlined"
                color="info"
                onClick={() => handleModalOpen(otherUser.userId)}
                sx={{
                  fontWeight: 600,
                  mb: 2,
                  px: 0.5,
                  py: 1,
                }}
              >
                {interpolate(
                  programTypeText?.variations?.individual?.relationships
                    ?.view_profile_button_label ?? '',
                  {
                    firstName: otherUser.firstName ?? '',
                    lastName: otherUser.lastName ?? '',
                  },
                  'View Profile',
                )}
              </Button>
            );
          })}
        </Box>
      </ThemeProvider>
      <Divider sx={{ mt: 2 }} />
      <RelationshipViewProfileModal
        isOpen={!!selectedUserId}
        handleClose={handleModalClose}
        title={modalTitle}
        closeButtonLabel={modalCloseButtonLabel}
        closeButtonAction={handleModalClose}
        programSlug={programSlug}
        userRole={getSelectedUserRole(selectedUserId)}
        profileData={getSelectedUsersProfileData(selectedUserId)}
        membershipData={getSelectedUsersMembershipData(selectedUserId)}
      />
    </>
  );
}
